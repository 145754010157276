/*https://ionicframework.com/docs/layout/global-stylesheets */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/menu";

// Fonts --------------------------------------------------
@font-face {
  font-family: "zilla";
  src: url("./assets/fonts/ZillaSlab-Regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "zilla-bold";
  src: url("./assets/fonts/ZillaSlab-Bold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "inter-medium";
  src: url("./assets/fonts/Inter-Medium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "inter-semibold";
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "inter-bold";
  src: url("assets/fonts/Inter-Bold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "inter-extrabold";
  src: url("assets/fonts/Inter-ExtraBold.ttf") format("truetype");
  font-style: normal;
}

* {
  font-family: "inter", serif;
}

.no-icon {
  ion-select::part(icon) {
    display: none !important;
  }
}

.canvasWrapper {
  position: relative;
}

.marca-dgua {
  .canvasWrapper::after {
    content: url("./assets/imgs/marca-terralogs.png");
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.9;
    overflow: hidden;
  }
}

.loading-app {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.5);

  ion-backdrop {
    background-color: var(--ion-backdrop-color, #FFF);
  }

  .loading-wrapper {
    display: block;
    text-align: center;
    box-shadow: none;
    background: transparent none !important;

    ion-spinner {
      width: 100px;
      height: 100px;
      background-image: url("assets/imgs/trator.gif");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    .loading-content {
      font-size: 0.563rem;
      text-transform: capitalize;
      animation: blinker 2s linear infinite;
      color: var(--ion-color-primary);
      margin-left: unset;
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }
  }

  .spinner-dots {
    &.sc-ion-loading-md {
      color: transparent;
    }

    &.sc-ion-loading-ios {
      color: transparent;
    }
  }

  .loading-content {
    &.sc-ion-loading-ios {
      color: white;
    }
  }
}

ion-spinner {
  width: 100%;
  transform: scale(2);
  --color: green;
}

[role=button] {
  cursor: pointer;
}

.bold-green {
  color: green;
  display: inline-block;
  font-weight: bold;
}

// -------------------------------------------------------

//ion-toolbar,
ion-content {
  --background: #F7FAFC;

  &::-webkit-scrollbar {
    //display: none;
    height: 6px;
    border-radius: 10px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d4dce8;
    border-radius: 10px;
  }
}

ion-header {
  &.header-md::after {
    display: none;
  }

  ion-toolbar {
    --padding-end: 22px;
    --padding-top: 16px;
    --padding-bottom: 16px;

    &.background-white {
      --background: #ffffff;
      border-bottom: 1px solid var(--ion-color-border-light);
    }

    ion-title {
      color: var(--ion-color-text-dark);
      font-family: inter-bold;
    }

    ion-buttons {
    }

    @media (max-width: 580px) {
      --padding-end: 16px;
      --padding-top: 16px;
    }
  }
}

ion-content {
  --padding-end: 32px;
  --padding-start: 32px;
  --padding-top: 32px;
  --padding-bottom: 32px;

  @media (max-width: 580px) {
    --padding-end: 16px;
    --padding-top: 16px;
    --padding-start: 16px;
    --padding-bottom: 16px;
  }
}

ion-alert {
  .alert-radio-group.sc-ion-alert-ios, .alert-checkbox-group.sc-ion-alert-ios {
    border-top: 1px solid transparent !important;
    padding: 0 4px;
  }

  &.multiple-select-alert {
    .alert-wrapper {
      .alert-head {
        h2 {
          font-weight: bold !important;
          color: var(--ion-color-dark);

          &.alert-title {
            display: none;
          }
        }
      }
    }
  }
}

.width-full {
  width: 100%;
}

.pd-tp-22 {
  padding-top: 22px;
}

.mg-top-16 {
  margin-top: 16px;
}

.mg-tp-22 {
  margin-top: 22px;
}

.mg-bt-22 {
  margin-bottom: 22px;
}

.mg-bt-0 {
  margin-bottom: 0;
}

.border-top-light-shade {
  border-top: 1px solid var(--ion-color-light-shade);
}

.container-table {
  border-radius: 16px;
  border: .55px solid rgba(222, 229, 239, .4);
}

ion-button {
  height: 42px;
  font-size: 14px;
  letter-spacing: 0;
  text-transform: none;
  font-family: inter-bold;
  --border-radius: 6px;
  --padding-end: 22px;
  --padding-start: 22px;

  &.btn-small {
    font-size: 12px;
    height: 36px;
  }

  &.btn-del-input {
    height: 46px;
    min-width: 58px;
    --border-radius: 6px;
    --padding-end: 6px;
    --padding-start: 6px;
  }

  &.btn-disabled {
    --color: var(--ion-color-medium-base);
    --background: var(--ion-color-medium-tint);
    --box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
  }

  &.btn-primary {
    --color: #ffffff;
    --background: var(--ion-color-primary);
    --background-focused: rgba(var(--ion-color-primary-rgb), .4);
    --background-hover: rgba(var(--ion-color-primary-rgb), .4);
    --background-activated: rgba(var(--ion-color-primary-rgb), .4);
  }

  &.btn-atencao {
    --color: #FFFFFF;
    --background: var(--ion-color-alert-base);
    --background-hover-opacity: .2;
    --background-focused-opacity: .2;
    --background-activated-opacity: .2;
    --background-focused: rgba(247, 147, 111, .4);
    --background-hover: rgba(247, 147, 111, .4);
    --background-activated: rgba(247, 147, 111, .4);
  }

  &.border-primary,
  &.btn-light {
    --color: var(--ion-color-primary);
    --color-activated: var(--ion-color-primary);
    --background: transparent;
    --background-focused: rgba(var(--ion-color-primary-rgb), .4);
    --background-hover: rgba(var(--ion-color-primary-rgb), .4);
    --background-activated: rgba(var(--ion-color-primary-rgb), .4);

    ion-icon {
      color: var(--ion-color-primary);
    }
  }

  &.btn-light {
    --box-shadow: none;
  }

  &.btn-white {
    --color: var(--ion-color-primary);
    --color-activated: #FFFFFF;
    --background: #FFFFFF;
    --background-hover-opacity: .2;
    --background-focused-opacity: .2;
    --background-activated-opacity: .2;
    --background-focused: rgba(var(--ion-color-primary-rgb), .8);
    --background-hover: rgba(var(--ion-color-primary-rgb), .8);
    --background-activated: rgba(var(--ion-color-primary-rgb), .8);
    --box-shadow: 0 4.88889px 61.1111px rgba(100, 87, 87, 0.05);
  }

  &.border-primary {
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-primary);
  }

  &.border-grey-light {
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-medium-tint);
    --color: var(--ion-color-dark-default);
    --box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
  }

  &.border-danger {
    --border-width: 1px;
    --border-style: solid;
    --color: var(--ion-color-danger);
    --border-color: var(--ion-color-danger);
    --background-hover: rgba(247, 147, 111, .4);
    --background-focused: rgba(247, 147, 111, .4);
    --background-activated: rgba(247, 147, 111, .4);
  }

  &.border-atencao {
    --border-width: 1px;
    --border-style: solid;
    --color: var(--ion-color-alert-base);
    --border-color: var(--ion-color-alert-base);
    --background-hover: rgba(var(--ion-color-danger-rgb), .3);
    --background-focused: rgba(var(--ion-color-danger-rgb), .3);
    --background-activated: rgba(var(--ion-color-danger-rgb), .3);
  }

  &.btn-circle-primary {
    width: 32px;
    height: 32px;
    --padding-end: 8px;
    --padding-start: 8px;
    --border-radius: 50%;
    --box-shadow: none;
    --background: #E9F8F2;
    --color: var(--ion-color-primary);
    --background-focused: rgba(var(--ion-color-primary-rgb), .4);
    --background-hover: rgba(var(--ion-color-primary-rgb), .4);
    --background-activated: rgba(var(--ion-color-primary-rgb), .4);
  }

  &.btn-icon-only {
    width: 40px;
    height: 40px;
    margin: 0 0 0 12px;
    --color: var(--ion-color-medium-base);
    --padding-end: 0;
    --padding-start: 0;
    --border-radius: 8px;
    --background: #F0F6FA;
    --box-shadow: 0 0 8px rgba(0, 0, 0, 0.06);
    --background-hover: rgba(160, 160, 160, .8);
    --background-focused: rgba(160, 160, 160, .7);
    --background-activated: rgba(160, 160, 160, .7);

    ion-icon {
      font-size: 24px;
    }
  }
}

ion-select {
  &.btn-small {
    font-size: 12px;
    height: 36px;
  }

  &.btn-white {
    background: #FFFFFF;
    box-shadow: 0 4.88889px 61.1111px rgb(100 87 87 / 5%);
    --placeholder-opacity: 1;
  }

  &.border-grey-light {
    border-width: 1px;
    border-style: solid;
    border-color: var(--ion-color-medium-tint);
    box-shadow: 0 0 1px rgb(12 26 75 / 24%), 0 3px 8px -1px rgb(50 50 71 / 5%);
    border-radius: 6px;
  }

  &.font-bold {
    font-family: inter-bold, serif;
    font-weight: bold;
  }
}

.title-divider {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: var(--ion-color-text-dark);
  font-family: inter-bold;

  span {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: var(--ion-color-text-dark);
    font-family: inter-bold;
  }

  &.mg-top {
    margin-top: 32px;
  }
}

.label-divider {
  font-size: 16px;
  line-height: 25px;
  font-family: inter-semibold;
  color: var(--ion-color-dark-default);

  &.divider-border {
    padding-top: 32px;
    border-top: 1px solid var(--ion-color-border-light);
  }

  &.divider-border-bottom {
    padding: 4px 0 12px;
    border-bottom: 1px solid var(--ion-color-border-light);
  }

  &.sub-divider {
    font-size: 14px;
    line-height: 20px;
  }
}

.box-icone {
  @extend .display-flex;
  width: 46px;
  height: 46px;
  border-radius: 8px;
  background: var(--ion-color-medium-tint);

  &.cl-primary {
    background: #EEFFF4;

    ion-icon {
      color: var(--ion-color-primary);
    }
  }

  ion-icon {
    font-size: 18px;
  }
}

.display-flex {
  display: flex;
  align-items: center;
  justify-content: center;

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-around {
    justify-content: space-around;
  }
}

.vertical-align {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.dual-align {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.box-tipo {
  padding: 16px 26px;
  margin-bottom: 16px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: var(--ion-color-box-shadow-default);

  div.width-full.display-flex {
    //align-items: flex-start;
    align-items: inherit;
  }

  .label-divider {
    align-items: center !important;
  }

  .card-item-cadastro {
    flex: 0 0 47%;
    max-width: 47%;
    padding: 26px;
    margin: 22px 0;
    border-radius: 13px;
    background: #ffffff;
    border: 1px solid var(--ion-color-medium-tint);

    color: #808191;
    font-size: 12px;
    line-height: 16px;
    font-family: inter-medium;

    &.wd-full {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &.dp-nome {
      display: none;
    }

    span {
      color: #11142D;
      font-size: 18px;
      line-height: 24px;
      font-family: inter-semibold;

      &.cl-primary {
        font-family: inter-bold;
        color: var(--ion-color-primary);
      }

      &.dp-nome {
        display: none;
      }
    }
  }

  .card-item-total {
    flex: 0 0 calc(100% - 520px);
    max-width: inherit;
  }

  .card-grafico {
    padding: 0;
    border: none;
    min-width: 320px;
    max-width: 480px;
  }

  @media (max-width: 640px) {
    padding: 10px 16px;

    div.width-full.display-flex {
      flex-direction: column;
    }

    .card-item-cadastro {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      margin: 10px 0;
    }
  }
}

.container-page-modal {
  height: 100%;
  width: 100%;

  .modal-content {
    position: relative;
    flex: 1;
    max-width: 900px;
    max-height: 100%;
    min-height: 500px;
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid var(--ion-color-border-light);
    box-shadow: 0 3px 8px -1px rgba(50, 50, 71, 0.05);
    filter: drop-shadow(0px 0 1px rgba(12, 26, 75, 0.24));

    &.modal-form-documentos {
      max-width: 620px;
    }

    .titulo {
      height: 60px;
      font-size: 16px;
      line-height: 25px;
      font-family: inter-semibold;
      color: var(--ion-color-dark-default);
      padding: 6px 22px;
      border-radius: 16px 16px 0 0;
      border-bottom: 1px solid var(--ion-color-border-light);
    }

    .form-content {
      form {
        ion-input {
          &.hidden {
            display: none;
          }
        }
      }
    }

    .box-buttons {
      z-index: 9999950 !important;
      bottom: 0;
      position: absolute;
      //position: fixed; ***NAO FUNCIONA NO SAFARI/IOS***
      background: #FFFFFF;
      padding: 22px;
      justify-content: flex-end;
      border-radius: 0 0 16px 16px;
      //box-shadow: 0 -8px 15px rgba(162, 162, 162, 0.08);
      border-top: 1px solid rgba(237, 242, 247, 0.25);

      &.justify-center {
        justify-content: center;
      }

      &.pd-end-start {
        padding-left: 22px;
        padding-right: 22px;
      }

      ion-button {
        height: 38px;
        min-width: 98px;
        margin: 0 0 0 12px;

        &.no-margin {
          margin: 0;
        }
      }

      //***NAO FUNCIONA***
      //@supports (overflow:-webkit-marquee) and (justify-content:inherit) {
      //  position: absolute!important;
      //}
    }

    @media (max-width: 580px) {
      width: 100%;
      height: 100%;
      border-radius: 0;

      .box-buttons {
        left: 0;
        bottom: -1px;
        position: fixed;
        border-radius: 0;
        padding: 22px 16px;
        z-index: 9999;
        background: white;
      }
    }
  }
}

.container-page-modal-selecionar-filtro {
  height: 100%;
  width: 100%;

  .modal-content {
    position: relative;
    flex: 1;
    max-width: 270px;
    min-height: 300px;
    max-height: 480px;
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid var(--ion-color-border-light);
    box-shadow: 0 3px 8px -1px rgba(50, 50, 71, 0.05);
    filter: drop-shadow(0px 0 1px rgba(12, 26, 75, 0.24));

    &.modal-form-documentos {
      max-width: 620px;
    }

    .titulo {
      height: 60px;
      font-size: 16px;
      line-height: 25px;
      text-align: start;
      font-family: inter-semibold;
      color: var(--ion-color-dark-default);
      padding: 6px 22px;
      border-radius: 16px 16px 0 0;
      border-bottom: 1px solid var(--ion-color-border-light);
    }

    .form-content {
      form {
        ion-input {
          &.hidden {
            display: none;
          }
        }
      }
    }

    .box-buttons {
      z-index: 9999950 !important;
      bottom: 0;
      position: absolute;
      //position: fixed; ***NAO FUNCIONA NO SAFARI/IOS***
      background: #FFFFFF;
      padding: 22px;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 16px 16px;
      //box-shadow: 0 -8px 15px rgba(162, 162, 162, 0.08);
      border-top: 1px solid rgba(237, 242, 247, 0.25);

      &.justify-center {
        justify-content: center;
      }

      &.pd-end-start {
        padding-left: 22px;
        padding-right: 22px;
      }

      ion-button {
        height: 38px;
        min-width: 98px;

        &.no-margin {
          margin: 0;
        }
      }

      //***NAO FUNCIONA***
      //@supports (overflow:-webkit-marquee) and (justify-content:inherit) {
      //  position: absolute!important;
      //}
    }

    @media (max-width: 580px) {
      width: 100%;
      height: 100%;
      border-radius: 0;

      .box-buttons {
        left: 0;
        bottom: -1px;
        position: fixed;
        border-radius: 0;
        padding: 22px 16px;
        z-index: 9999;
        background: white;
      }
    }
  }
}

.modal-add-localizacao .container-page-modal .modal-content {
  max-width: 1000px;
}

.modal-notificacao {
  .modal-wrapper {
    --width: 540px;
    --height: 400px;
    border-radius: 8px;
    box-shadow: none;
    background: transparent;
  }
}

ion-modal {
  background: rgba(0, 0, 0, 0.3);

  &.modal-selecionar-filtro {
    .modal-wrapper {
      max-width: 260px;
      max-height: 292px;
      min-height: 300px;
    }
  }

  &.modal-selecionar-filtro .modal-wrapper,
  &.alerta-modal .modal-wrapper,
  &.arquivo-detalhe-modal .modal-wrapper,
  &.modal-formulario .modal-wrapper,
  &.modal-mapa .modal-wrapper,
  &.modal-cadastrar-planilha .modal-wrapper {
    box-shadow: none;
    background: transparent;
  }

  &.alerta-modal {
    //background: rgba(0, 0, 0, 0.3);

    .modal-wrapper {
      @media (max-width: 580px) {
        --width: 80%;
        --height: 80%;
      }
    }
  }

  &.modal-preenchimento-obrigatorio {
    .modal-wrapper {
      --width: 80%;
      --height: 80%;
    }
  }

  &.modal-formulario .modal-wrapper {
    --width: 100%;
    min-width: 70%;
    min-height: 90%;

    @media (max-width: 580px) {
      min-width: 100%;
      min-height: 100%;
      border-radius: 0;
    }
  }

  &.modal-mapa .modal-wrapper {
    --width: 100%;
    min-width: 90%;
    min-height: 90%;

    @media (max-width: 580px) {
      min-width: 100%;
      min-height: 100%;
      border-radius: 0;
    }
  }

  &.arquivo-detalhe-modal .modal-wrapper {
    --width: 100%;
    --height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}

ion-popover {
  --width: auto !important;

  &.custom-popover-select {
    .popover-wrapper {
      border-radius: 8px;

      .popover-content {
        border-radius: 8px;

        &.position-top {
          max-height: 400px;
          min-height: 300px;
          top: 50% !important;
          transform: translateY(-50%);
        }

        &::-webkit-scrollbar {
          width: 10px;
          border-radius: 8px;
          background: #FFFFFF;
        }

        &::-webkit-scrollbar-thumb {
          background: #d4dce8;
          border-radius: 10px;
        }
      }
    }

    &.min-wd-250 {
      .popover-wrapper {
        .popover-content .content-popover {
          min-width: 250px;
        }
      }
    }
  }

  ion-select-popover {
    ion-radio-group {
      ion-item {
        --inner-border-width: 0;
      }
    }

    ion-list {
      overflow-y: scroll;

      ion-item {
        height: 35px;
        --padding-start: 10px;
        /* border: 1px solid; */
        --min-height: 30px;

        ion-label {
          font-family: "inter", sans-serif !important;
          font-size: 14px !important;

          h3 {
            font-weight: bold !important;
          }
        }

        ion-radio {
          margin: 0 0 0 1rem;
        }
      }
    }
  }
}

ion-toast {
  --max-width: 500px !important;
}

ion-item.segment-buttons {
  &.is-margin {
    margin: 12px 44px 0;

    ion-label {
      margin-bottom: 10px;
      font-size: 17px;
      font-family: inter-medium;
      color: var(--ion-color-label);
    }
  }

  .select-button {
    .btn-add {
      min-width: inherit;
      height: 38px;
      margin-left: 8px;

      ion-icon {
        margin: 0;
        font-size: 18px;
        transform: rotate(45deg);
      }
    }
  }

  @media (max-width: 580px) {
    &.is-margin {
      margin: 12px 16px 0;
    }
  }
}

.container-box-values {
  padding: 26px;
  margin: 22px 0;
  border-radius: 13px;
  background: #ffffff;
  border: 1px solid var(--ion-color-medium-tint);

  .divider-border.no-border {
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }

  div.divider-border {
    margin-bottom: 22px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--ion-color-border-light);

    &.sub-divider {
      border-bottom: 1px solid transparent;
    }

    &.border-top {
      margin: 8px 0 16px;
      padding: 22px 0 0;
      border-bottom: 1px solid transparent;
      border-top: 1px solid var(--ion-color-border-light);
    }

    &.no-border {
      border-color: transparent;
    }
  }

  @media (max-width: 720px) {
    div.flex-wrap {
      display: block;
    }

    .ct-pecuaria {
      &:after {
        display: none
      }

      .ct-item {
        width: 100%;
        justify-content: flex-start;

        .form-value {
          flex: 0 0 50%;
        }
      }
    }
  }
  @media (max-width: 640px) {
    .ct-pecuaria .ct-item {
      flex-direction: column;
    }
  }
}

ion-toggle {
  margin-right: 12px;
  width: 44px;
  height: 24px;
  --handle-height: 16px;
  --handle-width: 16px;
  --handle-spacing: 4px;
}

.form-value {
  align-self: flex-start;
  flex: 0 0 30%;
  position: relative;
  min-width: 160px;
  padding: 0 3px 0 48px;
  margin: 0 6px 22px 0;
  font-size: 12px;
  line-height: 14px;
  font-family: inter-medium;
  color: var(--ion-color-text-shade);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.color-primary {
    color: var(--ion-color-primary);
  }

  &.wd-80 {
    flex: 0 0 80% !important;
  }

  &.box-title,
  &.no-margin {
    margin-bottom: 0;
  }

  &.no-padding {
    padding-left: 0;
  }

  &.toggle {
    flex: inherit;
    align-self: auto;
    margin-right: 16px;
    margin-bottom: 0;
  }

  b {
    color: #11142D;
    font-size: 16px;
    line-height: 22px;
    font-family: inter-bold;

    &.color-primary {
      color: var(--ion-color-primary);
    }
  }

  .box-icone {
    @extend .vertical-align;
    left: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    ion-icon {

    }
  }
}

.select-button {
  padding: 4px;
  border-radius: 6px;
  background: #F6F7F9;
  margin-bottom: 0;

  ion-button {
    margin: 0 8px 0 0;
    min-width: 70px;
    --color: #6D6D6D;
    --border-radius: 6px;

    &.dp-none {
      display: none
    }

    &.btn-white {
      --color: var(--ion-color-dark);
    }

    &.is-placeholder {
      color: rgba(var(--ion-color-dark-rgb), .4);
    }

    ion-icon {
      font-size: 12px;
      margin-left: 6px;
    }
  }
}

.content-btn-ano {
  padding: 4px;
  border-radius: 6px;
  background: #F6F7F9;

  ion-button {
    margin: 0;
    --color: #6D6D6D;
    --border-radius: 6px;
  }
}

ion-item.wd-50 {
  @media (max-width: 640px) {
    flex: 0 0 100% !important;
    width: 100% !important;
    max-width: 100% !important;

    ion-button.btn-del-input {
      margin-top: 44px !important;
    }
  }
}

.modal-content {
  .form-content {
    padding: 40px;
    overflow: auto;
    margin-bottom: 85px;
    min-height: 500px;
    max-height: 60vh;

    &::-webkit-scrollbar {
      width: 12px;
      border-radius: 10px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #d4dce8;
      border-radius: 10px;
    }

    .box-observacoes {
      padding: 16px;
      font-size: 12px;
      line-height: 20px;
      color: var(--ion-color-text-shade);
      background: #F7FAFC;
      border-radius: 16px;

      span {
        font-size: 14px;
        line-height: 24px;
        font-family: inter-semibold;
      }
    }

    form, .form {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 0;

      div {
        flex: 0 0 47%;
        margin-bottom: 22px;

        &.type-checkbox {
          ion-item {
            --min-height: 10px;
            height: 20px;

            ion-label {
              margin-bottom: 0;
              margin-top: -16px;
            }
          }
        }

        &.container-socios-lista {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 18px;
          padding-right: 70px;
          position: relative;

          ion-item {
            flex: 0 0 47%;
            margin-bottom: 0;

            .item-select {
              margin-bottom: 0;
            }
          }

          .type-button {
            display: flex;
            align-items: center;
          }

          .btn-del-input {
            margin: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            --background: #fff;
          }
        }
      }

      div, ion-item {
        &.wd-full {
          flex: 0 0 100%;
        }
      }

      ion-item {
        flex: 0 0 47%;
        margin-bottom: 18px;

        --padding-end: 0;
        --inner-padding-end: 0;
        --border-radius: 6px;
        --background: transparent;
        --color: var(--ion-color-text-shade);

        &.mg-bt-0 {
          margin-bottom: 0;
        }

        &.divider-item {
          margin-top: 16px;
          margin-bottom: 10px;

          .label-value {
            margin-bottom: 0;
            font-family: inter-semibold;
          }
        }

        &.type-searchbar {
          //z-index: 9999;
          overflow: inherit;
          position: relative;

          &::part(native) {
            z-index: inherit !important;
          }
        }

        &.no-wd-flex {
          flex: inherit;
          margin-bottom: 0;
        }

        .item-select {
          margin-bottom: 0;
        }

        ion-label {
          margin-bottom: 10px;
          font-family: inter-medium;
          color: var(--ion-color-label);
          font-size: 13px;
          transform: translateY(50%) scale(0.99) !important;

          &.primary {
            color: var(--ion-color-primary)
          }
        }

        ion-checkbox {
          margin: 2px 10px 2px 0;
        }

        ion-searchbar,
        .item-select,
        ion-input {
          width: 100%;
          min-height: 48px;
          border-radius: 6px;
          background: var(--ion-color-border-light);

          --padding-end: 12px;
          --padding-start: 12px;
          --background: var(--ion-color-border-light);

          span {
            margin-left: 7px;
          }

          > [slot=end] {
            order: 1;
            margin-right: 8px;
          }

          .item-select {
            position: relative;
            padding: 0 0 0 12px;
            margin-bottom: 0 !important;
            color: var(--ion-color-text-shade);

            &.disabled {
              color: rgba(var(--ion-color-text-shade-rgb), .5) !important;
            }

            &.disabled:after {
              content: '';
              display: block;
              z-index: 999;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              position: absolute;
              border-radius: 6px;
              background: rgba(255, 255, 255, .1);
            }

            &.cl-placeholder {
              color: rgba(var(--ion-color-text-shade-rgb), .5);
            }

            ion-button {
              margin: 0;

              ion-icon {
                font-size: 14px;
                color: rgba(var(--ion-color-text-shade-rgb), .5);
              }
            }
          }


          .btn-del-input {
            margin: 33px 0 0 8px
          }
        }

        ion-searchbar {
          --border-radius: 6px;
          --box-shadow: none;

          .searchbar-input-container {
            flex: inherit;
            margin-bottom: 0;
          }
        }
      }

      ion-label {
        font-size: 17px;
        font-family: inter-medium;
        line-height: 23px !important;
        color: var(--ion-color-label);

        &.label-disabled {
          opacity: 0.3;
        }
      }

      .box-buttons-bottom {
        flex: 0 0 100%;
        margin-top: 32px;
      }

      .item-select {
        ion-button {
          --padding-start: 4px;
          --padding-end: 4px;
        }

        ion-select {
          &.item-select-full {
            max-width: 100%;
            padding-right: 0.5rem !important;
          }
        }
      }

      .type-select {
        position: relative;

        .item-select {
          width: 100%;
          min-height: 48px;
          border-radius: 6px;
          padding: 0 12px;
          background: var(--ion-color-border-light);
          color: var(--ion-color-text-shade);

          &.cl-placeholder {
            color: rgba(var(--ion-color-text-shade-rgb), .5);
          }
        }

        ion-label {
          //opacity: .8;
          //font-size: 17px;
          //line-height: 23px !important;
          //font-family: inter-medium;
          //color: var(--ion-color-label);
        }
      }

      .item-checkbox {
        margin: -12px 0 0;
        font-size: 14px;
        line-height: 15px;
        color: var(--ion-color-text-shade);
        font-family: inter-medium;

        ion-checkbox {
          margin-right: 8px;
        }
      }

      .box-proprietario {
        margin: 0;
        padding: 0 70px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn-del-input {
          margin: 15px 0 0 12px;
          z-index: 99;
        }
      }

      @media (max-width: 640px) {
        display: block;
      }
    }

    .box-buttons {
      padding: 16px 44px 22px !important;
    }

    @media (max-width: 640px) {
      max-height: 90%;
      padding: 22px 16px 100px;
    }

    @media (max-width: 580px) {
      .form-content {
        padding: 22px 22px 44px;

        form {
          display: block;
          margin-bottom: 0;
        }
      }
    }
  }

  .cdk-drag-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .example-box:last-child {
    border: none;
  }

  .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

ckeditor {
  .cke_inner,
  .cke_chrome,
  .cke_top {
    background: transparent !important;
  }

  .cke_contents.cke_reset,
  .cke_bottom {
    border-style: solid;
    border-color: #EDF2F7;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
  }

  .cke_chrome {
    border: none;
  }

  .cke_top {
    border: none !important;
  }

  .cke_contents.cke_reset {
    background: #FFFFFF;
    border-width: 1px 1px 0;
    border-radius: 16px 16px 0 0;
  }

  .cke_bottom {
    padding: 6px 14px 12px;
    border-width: 1px;
    border-radius: 0 0 16px 16px;
  }

  .cke_combo_text {
    width: 70px !important;
  }
}

.submitted {
  .type-input,
  .input-border,
  .select-default {
    &.ion-invalid {
      --border-color: var(--ion-color-danger);
      --highlight-background: var(--ion-color-danger);
      --full-highlight-height: calc(var(--highlight-height) * var(--show-full-highlight));
      --inset-highlight-height: calc(var(--highlight-height) * var(--show-inset-highlight));
    }
  }
}

//highcharts-chart {
//  .highcharts-container {
//    height: 150px !important;
//    border: 1px solid red !important;
//
//    .highcharts-series-group {
//      height: 130px !important;
//      border: 1px solid #d500ff !important;
//    }
//  }
.leaflet-tooltip {
  position: absolute;
  padding: 0;
  background: transparent;
  border: 0px solid #000;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #FFFFFF;
  text-align: center;

  p {
    margin: 0;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  }

  .numero {
    font-size: 42px;
  }
}
