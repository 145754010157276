// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #009B45;
  --ion-color-primary-rgb: 0, 155, 69;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #067538;
  --ion-color-primary-tint: #0abb59;

  /** secondary **/
  --ion-color-secondary: #4C6FFF;
  --ion-color-secondary-rgb: 76, 111, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3155ea;
  --ion-color-secondary-tint: #6b87fb;

  /** tertiary **/
  --ion-color-tertiary: #68DBF2;
  --ion-color-tertiary-rgb: 104, 219, 242;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #38b7d7;
  --ion-color-tertiary-tint: #7cdff5;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #425466;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #E4E4E4;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** complementary colors **/
  --ion-color-complementary-base: #3754DB;
  --ion-color-complementary-tint: #FF92AE;

  --ion-color-blue-900: #0A1629;
  --ion-color-alert-base: #F7936F;
  --ion-color-alert-shade: #FFA24C;

  /** Grey scale **/
  --ion-color-grey: #A9A9A9;
  --ion-color-grey-50: #FAFAFA;
  --ion-color-grey-100: #F5F5F5;
  --ion-color-grey-200: #EEEEEE;
  --ion-color-grey-300: #E0E0E0;
  --ion-color-grey-400: #BDBDBD;
  --ion-color-grey-500: #9E9E9E;
  --ion-color-grey-600: #757575;
  --ion-color-grey-700: #616161;
  --ion-color-grey-800: #424242;
  --ion-color-grey-900: #212121;
  --ion-color-black: #000000;

  --ion-color-line-light: #E4E4E4;
  --ion-color-border-light: rgba(237, 242, 247, 0.79);
  --ion-color-text-light: #A0AEC0;
  --ion-color-text-medium: #7A7A9D;
  --ion-color-text-shade: var(--ion-color-dark-tint);
  --ion-color-label: #808191;
  --ion-color-text-shade-rgb: 66, 84, 102;
  --ion-color-text-dark-medium: #464646;
  --ion-color-text-dark: #27272E;
  --ion-color-dark-default: #16192C;
  --ion-color-medium-base: #A0A0A0;
  --ion-color-input-placeholder: var(--ion-color-text-medium-shade);
  --ion-color-text-medium-shade: #7A828A;
  --ion-color-box-shadow-default: 0 3px 8px -1px rgba(50, 50, 71, 0.05);

  // Menu
  --bg-item-menu-selecionado: #00AE4E;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}
