ion-split-pane {
  --side-max-width: 260px;
  --side-min-width: 260px;
  --border: 1px solid #EDF2F7;
}

ion-menu.menu-principal-side {
  min-width: 250px;

  ion-content {
    --padding-end: 16px;
    --padding-start: 16px;
    --background: var(--ion-item-background, var(--ion-background-color, #fff));

    ion-list.list-contatos {
      position: relative;
      margin-top: 32px;
      padding: 32px 0 0;

      &:after {
        left: 50%;
        top: 0;
        position: absolute;
        transform: translateX(-50%);

        display: block;
        content: '';
        width: 90%;
        height: 1px;
        background: var(--ion-color-border-light);
      }
    }

    ion-list.list-bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0 16px 22px;

      ion-menu-toggle {
        ion-item {
          &:hover {
            --background: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }

    ion-badge {
      font-size: 12px;
      line-height: 12px;
      font-family: inter-bold;
      border-radius: 19px;

      --padding-end: 8px;
      --padding-start: 8px;
      --color: var(--ion-color-primary);
      --background: rgba(var(--ion-color-primary-rgb), .2);

      &.mg-left {
        margin-left: 22px;
      }
    }

    ion-list-header {
      cursor: pointer;
      position: relative;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      font-family: inter-semibold;
      color: var(--ion-color-text-medium);

      img {
        margin: 16px 0;
      }
    }

    ion-item ion-label {
      font-size: 14px !important;
      line-height: 17px;
      font-family: inter-semibold !important;
      color: var(--ion-color-text-shade);
    }

    ion-menu-toggle {
      ion-item {
        cursor: pointer;

        ion-icon {
          margin-right: 14px;
          color: var(--ion-color-text-dark);
        }

        ion-label {

        }
      }

      ion-item.selected {
        ion-label {
          color: var(--ion-color-primary);
        }
      }
    }

    .item-contato {
      ion-avatar {
        background: #F1F1F1;
        font-family: inter-semibold;
        color: var(--ion-color-text-dark-medium);
      }

      ion-icon {
        color: var(--ion-color-primary);
      }

      ion-label {
        font-size: 14px;
        line-height: 23px;
        font-family: inter-semibold;
        color: var(--ion-color-text-shade);

        span {
          font-size: 12px;
          line-height: 20px;
          color: var(--ion-color-text-medium);
        }
      }
    }

    .load-contato {
      height: 67px;
      padding: 0 16px;
    }

    ion-skeleton-text {
      &.avatar {
        width: 36px;
        height: 36px;
        margin: 8px 16px 8px 0;
        border-radius: 50%;
      }
    }
  }
}
